import { Button, Input, message } from 'antd'
import { ChangeEvent, useEffect, useMemo, useRef } from 'react'
import { useState } from 'react';
import { useCreateNewDepartmentMutation, useUpdateDepartmentMutation } from '../../slice/departmentsApiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectDepartments, setDepartmentsData, setIsAddingNewDepartment } from '../../slice/departmentsSlice';
import { apiStatuses, errorMessage } from '../../../../shared/utils/constantData';
import { IDepartment } from '../../../../shared/utils/interfaces/departmentInterface';
import { CloseOutlined } from '@ant-design/icons';
const NewDepartments = (department?: IDepartment) => {
    const dispatch = useDispatch()
    const [createNewDepartment, { isLoading, isSuccess, data, isError, error }] = useCreateNewDepartmentMutation()
    const [updateDepartment, { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate, data: dataUpdate, isError: isErrorUpdate, error: errorUpdate }] = useUpdateDepartmentMutation()
    const allDepartments = useSelector(selectDepartments);
    const inputRef = useRef<any>(null);
    const [text, setText] = useState(department ? department?.name : '');

    useEffect(() => {
        if (isSuccessUpdate) {
            const resData = {
                ...dataUpdate.data,
                editable: false,
            };
            const updatedDepartments = allDepartments?.map((dept: IDepartment) => {
                if (dept?.id === resData?.id) {

                    return {
                        ...dept,
                        editParent: false,
                        name: dataUpdate?.data?.name,
                        editable: false
                    };
                }
                return dept;
            });
            dispatch(setDepartmentsData(updatedDepartments));
            message.success(dataUpdate?.message)
        } else if (isErrorUpdate) message.error(errorUpdate?.status === apiStatuses?.conflict ? errorUpdate?.data?.message : errorMessage);
        
        // eslint-disable-next-line
    }, [isSuccessUpdate, dataUpdate, isErrorUpdate])

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus(); // Focus the textarea
        }
    }, []);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setText(e.target.value);
    };

    const isDeptNameUnique = () => {
        return !allDepartments?.some(({ name }) => name?.trim().toLowerCase().replace('', '') === text?.trim().toLowerCase().replace('', ''))
    }

    const handleAddNewDepartment = async () => {
        if (text?.trim()?.length === 0) {
            message.error('Department name cannot be empty');
            return;
        }
        const isUniqueName = isDeptNameUnique()
        isUniqueName ? await createNewDepartment({ name: text }) : message.error('Department name must be unique per company')
    }
    //create
    useEffect(() => {
        if (isSuccess) {
            message.success(data?.message)
            dispatch(setIsAddingNewDepartment(false));
            dispatch(setDepartmentsData([data?.data, ...allDepartments]))
        } else if (isError) message.error(error?.status === apiStatuses?.conflict ? error?.data?.message : errorMessage)
        // eslint-disable-next-line
    }, [isSuccess, data, isError, dispatch])

    const handleUpdateDepartment = async () => {
        const updateBody = {
            id: department?.id,
            name: text
        }
        if (text?.trim()?.length > 0) {
            const isUniqueName = isDeptNameUnique()
            isUniqueName ? await updateDepartment(updateBody) : message.error('Department name must be unique per company')
        } else {
            message.error(`Add department name`)
        }
    }

    const cancelDepartment = () => {

        const updatedDepartments = allDepartments?.map((dept: IDepartment) => {
            if (dept?.id === department?.id) {
                return {
                    ...dept,
                    editParent: false,
                };
            }
            return dept;
        });
        dispatch(setDepartmentsData(updatedDepartments));
    }
    const cancelNewDepartment = () => {
        dispatch(setIsAddingNewDepartment(false))
    }


    const isEnabled = useMemo(() => {
        return (text.trim().length > 0 && !isLoading) || (department && department?.name?.trim()?.length > 0);
    }, [department, isLoading, text])

    return (
        <>
            <div className="flex items-center justify-center">
                <div className="inline-flex items-center justify-center m-auto">
                    <div className="w-[16.625rem] h-[12rem] p-5 bg-white rounded-2xl flex-col justify-between items-start inline-flex">
                        <Input
                            placeholder="Write department name"
                            className="text-gray-600 text-base font-bold leading-normal py-2.5 h-6"
                            size="small"
                            bordered={false}
                            defaultValue={department && department?.name?.trim()?.length > 0 ? department.name : text}
                            onChange={handleInputChange}
                            onPressEnter={!department?.id ? handleAddNewDepartment : handleUpdateDepartment}
                            ref={inputRef}
                        />
                        <div className='flex w-full'>

                            <Button
                                type="primary"
                                className={`me-2 w-full btn-sm btn-primary pb-[1.7rem]`}
                                onClick={!department?.id ? handleAddNewDepartment : handleUpdateDepartment}
                                loading={isLoading || isLoadingUpdate}
                                disabled={!!(department?.id && department?.name.trim() === text.trim()) || !isEnabled || isLoadingUpdate}
                            >
                                {!department?.id ? 'Save' : 'Update'}
                            </Button>
                            <Button className='flex items-center justify-center' onClick={!department?.id ? cancelNewDepartment : cancelDepartment}><CloseOutlined /></Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewDepartments;

