import { IHeaderOverride } from "@cyntler/react-doc-viewer";
import { useSelector } from "react-redux";
import { selectCandidateCv } from "../../../modules/createJob/slice/candidateSlice";
import { getImageUrl } from '../../utils/functions/images';
import ChevronLeft from "../../assets/images/ChevronLeft.svg"
import ChevronLeftDisabled from "../../assets/images/ChevronLeftDisabled.svg"
import ChevronRight from "../../assets/images/ChevronRight.svg"
import ChevronRightDisabled from "../../assets/images/ChevronRightDisabled.svg"
import { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { perviousCandidateIndex, ICandidateNavigation } from "../../utils/interfaces/candidatesListFace";
export const Toolbar: IHeaderOverride = (state) => {
    const candidateCv = useSelector(selectCandidateCv);
    let [candidatesIds, setCandidatesIds] = useState<ICandidateNavigation[]>([])
    const { applicationId, jobId } = useParams();
    const navigate = useNavigate()
    const location = useLocation()
    const [totalCandidates, setTotalCandidates] = useState<number>(0)
    const [currentCandidate, setCurrentCandidate] = useState<number>(0)
    const handleDownload = () => {
        fetch(candidateCv).then(response => response.blob()).then((blob) => {
            const url = URL?.createObjectURL(blob)
            const fileName = candidateCv.split('/').pop() as string
            const aTag = document.createElement("a")
            aTag.href = url
            aTag.setAttribute("download", fileName)
            document.body.appendChild(aTag)
            aTag.click()
            aTag.remove()
        })
    }
    useEffect(() => {
        const storedCandidatesIds = JSON.parse(localStorage.getItem('candidatesIds') || '[]');
        setCandidatesIds(storedCandidatesIds);
    }, []);

    useEffect(() => {
        setTotalCandidates(candidatesIds?.length);
        console.log(candidatesIds)
        const currentCandidateIndex = candidatesIds?.findIndex((candidate: ICandidateNavigation) => candidate?.id === Number(applicationId));
        if (currentCandidateIndex !== -1) {
            setCurrentCandidate(currentCandidateIndex + 1);
        }
    }, [candidatesIds, applicationId]);

    const handlePrint = () => {
        const printWindow = window.open(state.currentDocument?.uri, state.currentDocument?.uri.split('.').pop() === 'docx' ? '_self' : '_blank') as Window;
        printWindow.addEventListener('load', () => {
            printWindow.print();
        });
    }
    const handlePreviousProfile = () => {
        const prevCandidate = candidatesIds[currentCandidate - perviousCandidateIndex]?.id;
        if (prevCandidate) {
            navigate(`/jobs/${jobId}/candidates/${prevCandidate}`, { state: { ...location?.state } });
        }
    }
    const handleNextProfile = () => {
        const nextCandidate = candidatesIds[currentCandidate]?.id;
        if (nextCandidate) {
            navigate(`/jobs/${jobId}/candidates/${nextCandidate}`, { state: { ...location?.state } })
        }
    }
    return (
        <div className='sticky top-0 left-0 z-50 flex justify-end w-full pb-5 pr-6 pt-7 bg-light-100'>
            {!location?.state?.fromSimilarity ?
                <div className="flex items-center justify-center flex-grow gap-2 text-sm text-gray-500">
                    <img src={currentCandidate === 1 ? ChevronLeftDisabled : ChevronLeft} alt="ChevronLeft"
                        className={`${currentCandidate !== 1 ? "cursor-pointer" : "cursor-not-allowed"}`} onClick={handlePreviousProfile} />
                    <p><span className="font-bold">{currentCandidate?.toString()}</span>/<span>{totalCandidates?.toString()}</span></p>
                    <img src={currentCandidate === totalCandidates ? ChevronRightDisabled : ChevronRight} alt="ChevronRight"
                        className={`${currentCandidate !== totalCandidates ? "cursor-pointer" : "cursor-not-allowed"}`} onClick={handleNextProfile} />
                </div> : null
            }
            <div className='flex justify-end gap-x-4'>
                <img src={getImageUrl('download')} alt="download" className='mx-3 cursor-pointer' onClick={handleDownload} />
                <img className='cursor-pointer' onClick={handlePrint} src={getImageUrl('Printer')} alt='print' />
            </div>
        </div>
    )
};