import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'antd';
import BlueWarningIcon from "../../../../../../shared/assets/images/blueWarningIcon.svg"
import ModalComponent from '../../../../../../shared/components/Modals/ModalComponent';
import ModalBody from '../../../../../../shared/components/Modals/ModalBody';
import { setSettingsModalOpen } from '../../../../../settings/slice/settingsSlice';
import { setSettingsModalActiveTab } from '../../../../slice/jobSlice';
import { settingsModalTabs } from '../../../../../../shared/utils/interfaces/createJobTabs';
import { selectIsEvaluationKitOpen, setEvaluationKitModal, setSelectedKit } from '../../../../../interview/slice/interviewSlice';
import { useNavigate } from 'react-router-dom';
import { selectCandidateProfile } from '../../../../slice/candidateSlice';
import useSubscriptionModal from '../../../../../../shared/utils/customHooks/useSubscribtionModal';
import { permissionsActions } from '../../../../../../shared/utils/interfaces/AuthInterface';

export const EvaluationKitModal = ({ getEvaluationKit, isLoading }: { getEvaluationKit: () => void, isLoading: boolean }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const isOpen = useSelector(selectIsEvaluationKitOpen);
    const candidateJobId = useSelector(selectCandidateProfile)?.jobId
    const { checkAccess } = useSubscriptionModal(permissionsActions.createInterviewKit)

    const closeModal = () => {
        dispatch(setEvaluationKitModal(false))
    };

    const handleAddGeneralEvaluation = async () => {
        await getEvaluationKit()
        if (!isLoading) closeModal()
    };

    const handleNavigateToGenerateKit = () => {
        if (checkAccess()) {
            navigate(`/jobs/${candidateJobId}/candidates`)
            dispatch(setSettingsModalOpen(true))
            dispatch(setSettingsModalActiveTab(settingsModalTabs.interviewKit))
            dispatch(setSelectedKit({ key: '0' }))
            closeModal()
        }
    }

    return (
        <ModalComponent
            visibleModal={isOpen}
            handleCloseModal={closeModal}
            content={
                <ModalBody
                    icon={BlueWarningIcon}
                    title="Evaluation Questions Kit!"
                    subtitle={
                        <p>
                            No interview kit assigned to this stage.
                        </p>
                    }
                >
                    <div className="flex gap-3 flex-nowrap">
                        <Button
                            className="basis-1/2 btn-secondary !text-gray-700 h-10"
                            onClick={handleAddGeneralEvaluation}
                            loading={isLoading}
                        >
                            Add General Evaluation
                        </Button>
                        <Button
                            className="h-10 basis-1/2 btn-primary"
                            onClick={handleNavigateToGenerateKit}
                            disabled={isLoading}
                        >
                            Generate Interview Kit
                        </Button>
                    </div>
                </ModalBody>
            }
        />
    );
};
