import { InterviewFormComponent } from "./InterviewTemplateForm";
import ArrowDropdown from "../../../../shared/components/Dropdown/ArrowDropdown";
import grayArrowDown from "../../../../shared/assets/images/ChevronDownGray.svg";
import ViewMode from "./ViewMode";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { setSelectedTemplate, selectSelectedTemplate, setTemplates, selectAllTemplates, setIsCreatingNewTemplate, selectIsCreatingNewTemplate } from "../../../interview/slice/interviewSlice";
import AiIcon from "../../../../shared/assets/images/outlineAiIcon.svg";
import WarningIconRed from "../../../../shared/assets/images/Icon.svg";
import { useGetAllInterviewsTemplatesQuery } from "../../slice/interviewApiSlice";
import { Button } from "antd";
import LazyLoader from "../../../../shared/components/LazyLoader/load";
import { errorMessage } from "../../../../shared/utils/constantData";
import BrokenRoute from "../../../../shared/components/Errors/BrokenRoute";
import { IListItem } from "../../../../shared/utils/interfaces/interviewKit";
import { permissionsActions } from "../../../../shared/utils/interfaces/AuthInterface";
import { useCheckAccess } from "../../../../shared/utils/customHooks/useCheckAccess";
export const InterviewTemplate: React.FC = () => {
  const dispatch = useDispatch();
  const selectedTemplate = useSelector(selectSelectedTemplate);
  const isCreatingNewTemplate = useSelector(selectIsCreatingNewTemplate);
  const templates: IListItem[] = useSelector(selectAllTemplates);
  const { data, isSuccess, isLoading, isError, error } = useGetAllInterviewsTemplatesQuery();

  useEffect(() => {
    if (isSuccess) {
      const formattedTemplates = Array.isArray(data?.data) ? data?.data?.map((template: { id: number; title: string }) => ({
        key: template?.id,
        label: template?.title,
      })) : [];
      formattedTemplates.push({
        key: "0",
        label: (
          <p onClick={() => {
            dispatch(setSelectedTemplate(formattedTemplates[formattedTemplates?.length - 1]))
            dispatch(setIsCreatingNewTemplate(true))
          }} className="flex text-base font-semibold text-indigo-700">
            <img className="mr-3" src={AiIcon} alt="ai" />
            Generate New Template
          </p>
        ),
      });
      dispatch(setTemplates(formattedTemplates));
      dispatch(setSelectedTemplate(formattedTemplates[0]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
  useEffect(() => {
    if (selectedTemplate?.key === "0") dispatch(setIsCreatingNewTemplate(true))
    if (Number(selectedTemplate?.key)) {
      dispatch(setIsCreatingNewTemplate(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedTemplate, templates, isSuccess, isCreatingNewTemplate]);
  useCheckAccess({
    error,
    navLink: '/settings/templates/email',
    permission: permissionsActions.getInterviewKitTemplate,
});
  return (
    <>
      {isLoading ?
        <div className="w-full flex flex-col justify-center h-[39rem]">
          <LazyLoader />
          <h3 className="mt-2 text-indigo-700">Get interview templates..</h3>
        </div> : isError ? <BrokenRoute
          icon={<img src={WarningIconRed} className='inline w-8 md:w-16' alt="Warning Icon" />}
          title={`Templates Not Found`}
          message={error?.data?.message ?? errorMessage}
          actions={
            <Button
              type='primary'
              className='btn-primary btn-md'
              onClick={() => window.location.reload()}
            >Reload the page</Button>
          }
        /> : <div>
          <div className="relative z-10 flex justify-between my-6 w-fit h-18">
            <div className="flex gap-2 text-[#4B5563] items-center">
              <div className="text-lg font-semibold leading-6 capitalize">{!Number(selectedTemplate.key) ? "Interview Kit Templates" : selectedTemplate.label}</div>
              <ArrowDropdown
                list={templates ? templates : []}
                imageSrc={grayArrowDown}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                withDivider={true}
              />
            </div>
          </div>
          {isCreatingNewTemplate ? <InterviewFormComponent /> : <ViewMode />}
        </div>}
    </>
  );
};