import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from '../../../modules/authentication/slice/authSlice';
import { setPlansModalOpen } from '../../../modules/settings/slice/settingsSlice';
import { permissionsActions, Roles } from '../interfaces/AuthInterface';
import { message } from 'antd';
import { roleError } from '../constantData';

const useSubscriptionModal = (action: permissionsActions) => {
    const dispatch = useDispatch()
    const allowedActions = useSelector(selectCurrentUser)?.plan?.allowedActions;
    const userRole = useSelector(selectCurrentUser)?.adminRole;

    const checkAccess = () => {
        if (!allowedActions?.includes(action)) {
            if (userRole === Roles.superAdmin) dispatch(setPlansModalOpen(true))
            else message.error(roleError)
            return false
        }
        return true
    };

    return { checkAccess };
};

export default useSubscriptionModal;
