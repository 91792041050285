import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useSubscriptionModal from './useSubscribtionModal';
import { apiStatuses } from '../constantData';
import { IUseCheckAccess } from '../interfaces/AuthInterface';

export const useCheckAccess = ({ error, navLink, permission }: IUseCheckAccess) => {
    const navigate = useNavigate();
    const { checkAccess } = useSubscriptionModal(permission);

    useEffect(() => {
        if (error?.data?.statusCode === apiStatuses.requestsLocked) {
            checkAccess();
            navigate(navLink);
        }
    }, [error, checkAccess, navigate, navLink]);
};
