import { useEffect, useState } from "react";
import { Form, Input, Alert, Button, message } from "antd";
import { getImageUrl } from "../../../../shared/utils/functions/images";
import { formHasErrors } from "../../../../shared/utils/functions/handleFormChanges";
import sparkles from "../../../../shared/assets/images/Sparkles.svg";
import { useCreateNewInterviewTemplateMutation } from "../../slice/interviewApiSlice";
import LazyLoader from "../../../../shared/components/LazyLoader/load";
import { Warning } from "../../../../shared/components/Warning/Warning";
import { useDispatch, useSelector } from "react-redux";
import { selectAllTemplates, setIsCreatingNewTemplate, setSelectedTemplate, setTemplates } from "../../slice/interviewSlice";
import { CheckboxDropdownItem } from "../../../../shared/utils/interfaces/settingsInterface";

export const InterviewFormComponent = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch()
  const [onSubmitError, setOnSubmitError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const allTemplates = useSelector(selectAllTemplates)
  const [createNewInterviewTemplate, { data, isLoading, isSuccess, isError, error }] = useCreateNewInterviewTemplateMutation();
  const ValidationError = (
    <img alt="Required" src={getImageUrl("ValidationError")} />
  );

  useEffect(() => {
    if (isLoading) setOnSubmitError(false);
    else if (isSuccess) {
      message.success('Interview template successfully generated')
      dispatch(setIsCreatingNewTemplate(false))
      const templates = allTemplates?.length ? allTemplates.slice(0, allTemplates.length - 1) : []
      const updatedTemplates = [...templates, { key: data?.data?.id, label: data?.data?.data?.title }]
      dispatch(setTemplates([...updatedTemplates, allTemplates[allTemplates.length - 1]]))
      dispatch(setSelectedTemplate({ key: data?.data?.id, label: data?.data?.data?.title }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isLoading, isError, error, data, dispatch])

  const onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setOnSubmitError(false);
  };

  const onFinish = async (values: { title: string, keywords: string }) => {
    const { keywords, title } = values
    const templates = allTemplates?.length ? allTemplates.slice(0, allTemplates.length - 1) : []
    const updatedInterviewTemplateChildren = templates?.length ? [...templates, { key: title, label: title }] : [{ key: title, label: title }]
    const interviewTemplateNames = updatedInterviewTemplateChildren?.map(({ label }: CheckboxDropdownItem) => label?.trim()?.toLowerCase().replace('', ''));
    const uniqueNames = new Set(interviewTemplateNames);
    const isUniqueName = uniqueNames.size === interviewTemplateNames.length;
    isUniqueName ? await createNewInterviewTemplate({ title, keywords: keywords.split(",") }) : message.error('Template name should be unique per company')
      ;
  };

  const onFinishFailed = () => {
    setOnSubmitError(true);

  };

  const handleFormChange = () => {
    const hasErrors = formHasErrors(form);
    setIsDisabled(hasErrors);
    if (hasErrors) {
      setOnSubmitError(true);
    } else {
      setOnSubmitError(false);
    }
  };

  return (
    <div>
      <Form
        form={form}
        className="text-start min-h-[70vh]  w-full max-w-full mx-auto generateForm relative"
        size="large"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onFieldsChange={handleFormChange}
      >
        <div className="w-full min-h-[40vh] flex flex-col justify-between">
          <div className="md:-mt-16">
            <Form.Item className="flex justify-end w-full mb-0">
              <Button
                disabled={isDisabled || isLoading}
                className="btn-primary !min-h-[2rem] w-max flex items-center md:mt-2"
                htmlType="submit"
              >
                <div>Generate</div>
                <img src={sparkles} alt="" />
              </Button>
            </Form.Item>
          </div>
          <div className="flex items-center justify-center w-full h-full mx-auto md:w-3/4 mt-44">
            <div className="w-full md:ml-12">
              <h1 className="py-2 pl-3 text-xs font-semibold text-gray-900 uppercase">
                Title and criteria
              </h1>
              {onSubmitError && (
                <Alert
                  className="mt-4"
                  onClose={onClose}
                  message="Please fill the missing data"
                  type="error"
                  closable
                />
              )}
              <Form.Item
                className="mb-0 input-3xl"
                name="title"
                rules={[{ required: true, message: ValidationError }]}
              >
                <Input
                  className={`text-lg font-medium bg-transparent text-gray-600 placeholder-gray-400`}
                  bordered={false}
                  placeholder="Write template title"
                />
              </Form.Item>
              <Form.Item
                className="mb-0 md:col-span-2"
                name="keywords"
                rules={[{ required: true, message: ValidationError }]}
              >
                <Input
                  className={`text-base font-light bg-transparent text-gray-600 placeholder-gray-400`}
                  bordered={false}
                  placeholder="Write your interview criteria keywords separated with “,” (ex. communication, collaboration,..)"
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className='mt-16 wrapper'>
          {isLoading &&
            <div>
              <LazyLoader />
              <p className='mt-4 text-base font-normal text-indigo-600'>Generating template..</p>
            </div>
          }
          {isError &&
            <div>
              <hr className='my-[4rem] border-dashed border-neutral-500' />
              <Warning icon={<img alt="Warning!" src={getImageUrl('warningGray')} />} title='Something went wrong!' message='Please refresh to generate the interview template' btnText='Refresh' />
            </div>
          }
        </div>
      </Form>
    </div>
  );
};
